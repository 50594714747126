.travelroy__input--secondary {
    border: 2px solid $secondary-color;
}

.travelroy__input--dark {
    border: 1px solid rgb(204, 204, 204) !important;
}

.travelroy__input {
    color: #868585;
}

.sb__date-range-picker {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 100%;
    width: 100%;
}